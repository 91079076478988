<template>
  <div id="navbar-main">
    <nav class="navbar navbar-expand-lg navbar-dark bg-nav fixed-top">
      <div class="container">
        <router-link class="navbar-brand text-capitalize text-center" to="/"
          ><img src="../assets/logo-white.png" class="img-fluid logo" alt="" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">About Us</a>
            </li> -->
            <li class="nav-item">
              <router-link
                to="/about"
                class="nav-link text-white pe-5 text-uppercase"
                >About Us</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/pricing"
                class="nav-link text-white pe-5 text-uppercase"
                >Packages & Pricing</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/safety-rules"
                class="nav-link text-white pe-5 text-uppercase"
                >Safety & Rules</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/contact"
                class="nav-link text-white pe-5 text-uppercase"
                >Contact</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from "jquery";
$(document).on("scroll", function () {
  if ($(document).scrollTop() > 5) {
    $("#navbar-main").addClass("shrink");
  } else {
    $("#navbar-main").removeClass("shrink");
  }
});
export default {
  components: {},
};
</script>
