<template>
  <div class="price">
    <section class="hero">
      <div class="hero-slide-1 vh-100">
        <div class="px-5 h-100 container d-flex align-items-center text-center">
          <div class="pt-xl">
            <h1 class="display-1 fw-bold v-text text-white pt-xl text-shadow">
              The cape town quad biking adventure you deserve!
            </h1>

            <a
              href="https://wa.me/+27823792293"
              target="_blank"
              class="
                btn btn-lg btn-blue
                h5
                v-text
                text-uppercase text-shadow
                shadow
                px-5
                mt-5
              "
              >Book Now!</a
            >
          </div>
        </div>
      </div>
    </section>

    <main class="">
      <section class="container">
        <div class="row pt-lrg pb-lrg">
          <div class="col-md-12 pt-5">
            <h2 class="v-text pb-5 text-center h2">Packages</h2>
            <p>
              We currently have five of our popular packages listed below. If
              you planning a children's birthday party, corporate event, or have
              larger groups of people wishing to sandboard/quad bike etc, and
              our packages below do not suit your specific requirements, then
              please <router-link>Contact Us Here.</router-link>
            </p>
          </div>
          <!-- pricing table  -->
          <div id="pricing-tables col-md-12">
            <div class="row">
              <div
                class="col-lg-3 col-md-6 col-xs-12 mb-4"
                v-for="(trip, index) in trips"
                :key="index"
              >
                <div class="single-table text-center">
                  <div class="plan-header">
                    <h3 class="v-text h4">{{ trip.title }}</h3>
                    <h4 class="plan-price">
                      {{ trip.price }}<span>/p.p</span>
                    </h4>
                  </div>

                  <ul class="plan-features">
                    <li>{{ trip.item1 }}</li>
                    <li>{{ trip.item2 }}</li>
                    <li>{{ trip.item3 }}</li>
                    <li>{{ trip.item4 }}</li>
                    <li>{{ trip.time }}</li>
                  </ul>
                  <a
                    href="https://wa.me/+27823792293"
                    target="_blank"
                    class="plan-submit hvr-bounce-to-right"
                    >Book Now</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <p>
            * Extra hours available at discounted rates after initial session.
            If you would like to plan a larger group or kids birthday parties,
            please contact us here
          </p>
          <p>
            *Transport to/from your accommodation to the dunes can be provided
            at an additional R300 per person. For organised groups of five
            people or more, a discounted rate of R220 per person will apply.
          </p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
// import "hooper/dist/hooper.css";

export default {
  components: {
    // Hooper,
    // Slide,
    // HooperNavigation,
  },
  data() {
    return {
      trips: [
        {
          title: "Quad Biking",
          item1: "Sand Dune Rides",
          item2: "Qualified Guides",
          item3: "Beginner and Advanced Riding Routes",
          item4: "All Equipment Provided",
          time: "1 Hour",
          price: "From R850",
        },
        {
          title: "Sandboarding",
          item1: "Sandboarding Lessons",
          item2: "Qualified Guides",
          item3: "All Equipment Provided",
          item4: "Special rates for kids parties",
          time: "1 Hour",
          price: "R600",
        },
        {
          title: "Combo 1",
          item1: "Sand Dune Rides",
          item2: "Sandboarding",
          item3: "Qualified Guides",
          item4: "All Equipment Provided",
          time: "3 Hour",
          price: "R1450",
        },
        {
          title: "Combo 2",
          item1: "Quad Biking",
          item2: "Paragliding",
          item3: "Qualified Guides/Instructors",
          item4: "All Equipment Provided",
          time: "3-4 Hour",
          price: "R2450",
        },
      ],
    };
  },
};
</script>
