<template>
  <div class="home">
    <section class="hero">
      <div class="hero-slide-1 vh-100">
        <div class="px-5 h-100 container d-flex align-items-center">
          <div class="pt-sml">
            <h1 class="display-1 fw-bold v-text text-white pt-xl text-shadow">
              the ultimate Quad Biking experience
            </h1>
            <h3 class="v-text pb-3 text-white text-shadow">
              Experience Cape Town like never before, with spectacular views of
              the city and our landmark table mountain..
            </h3>
            <a
              href="https://wa.me/+27823792293"
              target="_blank"
              class="
                btn btn-lg btn-blue
                h5
                v-text
                text-uppercase text-shadow
                shadow
                px-5
              "
              >Book Now!</a
            >
          </div>
        </div>
      </div>
    </section>

    <main class="">
      <section class="container">
        <div class="row text-center pt-lrg pb-lrg">
          <div class="col-md-4">
            <img
              src="../assets/professional.svg"
              class="img-fluid home-icons"
              alt=""
            />
            <h4 class="v-text text-uppercase pb-3 pt-4">Professional Guides</h4>
            <p>
              Our club has the most experienced Guides to help you get the most
              out of your experience.
            </p>
          </div>
          <div class="col-md-4">
            <img
              src="../assets/location.svg"
              class="img-fluid home-icons"
              alt=""
            />
            <h4 class="v-text text-uppercase pb-3 pt-4">Best Location</h4>
            <p>
              Quadbiking and Sandboard at a breathtaking spot, which has been a
              prime location for many years.
            </p>
          </div>
          <div class="col-md-4">
            <img
              src="../assets/safety.svg"
              class="img-fluid home-icons"
              alt=""
            />
            <h4 class="v-text text-uppercase pb-3 pt-4">Safety First</h4>
            <p>
              We offer a range of both beginner and pro equipment, including
              safe helmets, vests, sandboards, and more!
            </p>
          </div>
        </div>
      </section>
      <section class="hero-slide-2">
        <div class="container">
          <div class="row pt-lrg pb-lrg">
            <div class="col-md-12">
              <h2
                class="
                  display-2
                  fw-bold
                  v-text
                  text-white text-center
                  pt-xl
                  text-shadow
                "
              >
                What We Offer
              </h2>
              <h4 class="text-white text-center text-shadow">
                At Cape Town Quad Bikes, we offer a variety of adventures. Below
                are a few of our most popular offers that we hope will interest
                you.
              </h4>
            </div>
            <div class="card-group mt-lrg">
              <div class="card home-card">
                <img
                  src="../assets/cape-town-quad-bikes-quadbiking.webp"
                  class="card-img-top image-bw"
                  alt="Cape Town Quad Bikes - Quadbiking"
                />
                <div class="card-body text-center">
                  <h4 class="card-title v-text text-uppercase pb-3">
                    QuadBiking
                  </h4>
                  <h5 class="card-text text-red pb-3">From R850\60min</h5>
                  <a
                    href="https://wa.me/+27823792293?text=Hi%0aI%20am%20interested%20in%20quad%20biking"
                    target="_blank"
                    class="
                      btn btn-lg btn-blue-border
                      h5
                      v-text
                      text-uppercase text-shadow
                      px-5
                    "
                    >Book Now!</a
                  >
                </div>
              </div>
              <div class="card home-card">
                <img
                  src="../assets/cape-town-quad-bikes-sandboarding.webp"
                  class="card-img-top image-bw"
                  alt="Cape Town Quad Bikes - Sandboarding"
                />
                <div class="card-body text-center">
                  <h4 class="card-title v-text text-uppercase pb-3">
                    Sandboarding
                  </h4>
                  <h5 class="card-text text-red pb-3">From R600\120min</h5>
                  <a
                    href="https://wa.me/+27823792293?text=Hi%0aI%20am%20interested%20in%20sandboarding"
                    target="_blank"
                    class="
                      btn btn-lg btn-blue-border
                      h5
                      v-text
                      text-uppercase text-shadow
                      px-5
                    "
                    >Book Now!</a
                  >
                </div>
              </div>
              <div class="card home-card">
                <img
                  src="../assets/cape-town-quad-bikes-combo.webp"
                  class="card-img-top image-bw"
                  alt="Cape Town Quad Bikes - Quadbiking and Sandboarding Combo"
                />
                <div class="card-body text-center">
                  <h4 class="card-title v-text text-uppercase pb-3">Combo</h4>
                  <h5 class="card-text text-red pb-3">From R1450\60min</h5>
                  <a
                    href="https://wa.me/+27823792293?text=Hi%0aI%20am%20interested%20in%20your%20combo%20deals"
                    target="_blank"
                    class="
                      btn btn-lg btn-blue-border
                      h5
                      v-text
                      text-uppercase text-shadow
                      px-5
                    "
                    >Book Now!</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <section>
        <div class="row">
          <div class="col-md-6">
            <h2 class="pb-5 text-red v-text">Welcome to Adventure Cape Town</h2>
            <p>
              From the natural wonder that is Table Mountain to awe-inspiring
              desert landscapes and Precambrian limestone caves.
            </p>
            <p>
              Home to mountains, oceans, beautiful nature reserves and
              vineyards, Cape Town is the ideal spot for some of the world’s
              most unique and popular adventure activities. Discover a world of
              unique experiences and adrenaline fuelled adventures, and be
              inspired by the diversity of culture, cuisine, wine and wildlife.
              From tours and tastings at the most elegant wine farms, to
              adrenaline pumping quad bike rides in majestic, powdery dunes - we
              have you covered!
            </p>
            <p>
              Visit the Big 5, go sand boarding, paraglide over Cape Town for
              the best views imaginable, explore some of the best craft beer and
              gin distilleries, go shark cage diving, hike to natural pools
              where you can cliff jump into crystal waters, horseback ride along
              an endless coast, walk with lions or feed elephants, the list goes
              on and on and on. With almost two decades experience, Adventure
              Cape Town will ensure your craving for adventure is satisfied!
            </p>
            <p>Why go with the rest when you can go with the best ;)</p>
          </div>
          <div class="col-md-6 d-flex align-items-center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/_V_wr1xh5ag"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay;  picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section> -->
    </main>
  </div>
</template>

<script>
// import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
// import "hooper/dist/hooper.css";

export default {
  components: {
    // Hooper,
    // Slide,
    // HooperNavigation,
  },
  data() {
    return {};
  },
};
</script>
